import React        from 'react';
import PropTypes    from 'prop-types';
import Translations from '../../../../utils/Translations';

const AlbumButtonBought = (props) =>
	<div className={`${props.baseClassName}__button--buy is-disabled`}>
		<div className={`${props.baseClassName}__icon--button icon -icon-cart-full`} />
		<div className={`${props.baseClassName}__text--button`}>{Translations.get('VideoBought')}</div>
	</div>
;

AlbumButtonBought.propTypes = {
	baseClassName: PropTypes.string,
};

export default AlbumButtonBought;
