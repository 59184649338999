import React                                    from 'react';
import PropTypes                                from 'prop-types';
import Translations                             from '../../utils/Translations';
import Flux                                     from '../../flux/Flux';
import {VXPay, VXPayAction}                     from '../../utils/VXPay';
import ReloadHelper                             from '../../utils/ReloadHelper';
import VXButton                                 from "../SimpleElements/VXButton/VXButton";
import {VXButtonLineConfig, VXButtonLineStyle}  from '../SimpleElements/VXButton/VXButtonConfig';
import STYLES                                   from '../SimpleElements/VXButton/EnumVXButton';
import {COLOR_TYPE_DEFAULT, COLOR_TYPE_SPECIAL} from "../SimpleElements/VXButton/ButtonHelper";

class ActorPlaceholderTile extends React.Component {

	static openMessenger(e, actorId) {
		if (Flux.Guest.isLoggedIn()) {
			Flux.Messenger.selectChannelByActorId(actorId, true);
		} else {
			const action = new VXPayAction(Flux.Constants.ActionTypes.Messenger.SELECT_CHANNEL, {
				targetId:             actorId,
				storeAutoOpenSetting: true,
			});

			if (Flux.Guest.isAnonymousVoicecall()) {
				VXPay.openSignup({
					host:  actorId,
					texts: {
						BonusTextCustom: Translations.get('VoicecallVXPayTeasserText'),
					},
				}, action);
			} else {
				VXPay.openSignupOrLogin({
					host: actorId,
				}, action);
			}
		}
		if (typeof e === 'object' && e.cancelable) {
			e.preventDefault();
		}
	}

	constructor(props) {
		super(props);

		this.resetFilters          = this.resetFilters.bind(this);
		this.searchTagsInAllModels = this.searchTagsInAllModels.bind(this);
	}

	resetFilters() {
		if (typeof this.props.onResetClickFn === 'function') {
			this.props.onResetClickFn();
		} else {
			document.location.href = window.location.pathname + '#pjax-scrollto-pagination';
		}
	}

	searchTagsInAllModels() {
		ReloadHelper.reload(this.props.routes['SearchResultsVideos'].replace('searchpattern', this.props.searchTags[0]));
	}

	render() {
		let paddingStyleClass = '';
		let placeHolderButton;
		let searchInAllModels;
		let translatedMissingElementsInfo;
		if (this.props.searchTags.length === 0) {
			translatedMissingElementsInfo = this.props.textMissing.split(" ").map((value, index) => {
				const replacedValue = value.replace(':actorName', this.props.actorName);

				if (replacedValue !== value) {
					value = <span key={index} className="h-text-nowrap">{replacedValue}</span>;
				}

				return [value, " "];
			});

			const buttonColor     = this.props.guestIsLoggedIn ? COLOR_TYPE_DEFAULT : COLOR_TYPE_SPECIAL;
			const buttonKey       = this.props.guestIsLoggedIn ? 'actor_profile.overview_missing-map-info.button' : 'actor_profile.overview_missing-map-info.signup';
			const buttonIcon      = this.props.guestIsLoggedIn ? '-icon-chat-flat-line' : '-icon-arrow-right-full';

			placeHolderButton = <VXButton color={buttonColor}
			                              line1={[new VXButtonLineConfig(Translations.get(buttonKey))]}
			                              icon={buttonIcon}
			                              onClick={e => ActorPlaceholderTile.openMessenger(e, this.props.actorId)}
			/>;
		} else {
			if (this.props.hasVideos) {
				paddingStyleClass             = "actor-content-placeholder__max-height";
				translatedMissingElementsInfo =
					<span className="actor-content-placeholder__text-headline">{Translations.get('SearchVideosInAllModels')}</span>;
			} else {
				translatedMissingElementsInfo =
					<span className="actor-content-placeholder__text-headline">{Translations.get('MissingActorSearchTagsVideosInfo', this.props.actorName)}</span>;
			}

			if (this.props.isMobile) {
				const Link   = this.props.Link;
				const routes = this.props.routes;
				paddingStyleClass += " actor-content-placeholder__margin-top";

				searchInAllModels = <Link to={routes.getLink(routes.SEARCH_RESULTS, {':searchPattern': this.props.searchTags[0]})}>
					<button className="btn -btn-color-highlight -click-material">
						{Translations.get('SearchInAllModels')}
					</button>
				</Link>;
			} else {
				searchInAllModels = <button className="btn -btn-color-highlight -click-material" onClick={this.searchTagsInAllModels}>
					{Translations.get('SearchInAllModels')}
				</button>;
			}


		}

		return (
			<div className={this.props.gridTileClassName ? this.props.gridTileClassName + ' ' + paddingStyleClass : "col-lg-" + Math.min(12, (this.props.placeholderCols * (12 / this.props.gridCols))) + " col-xs-12 " + paddingStyleClass}>
				<div className={"box--fixed-ratio -unclickable h-width-full -ratio" + (4 * (this.props.placeholderCols)) + "_3"}>
					<div className="box--fixed-ratio__content">
						<div className="h-disp-table h-height-full h-width-full">
							<div className="h-disp-table-cell h-vertical-align-middle">
								<div className="col-xs-12 actor-content-placeholder h-text-align-center clearfix">
									<span className="actor-content-placeholder__text">
										{translatedMissingElementsInfo}
										<br />
									</span>
								</div>
								<div className="col-xs-12 actor-content-placeholder__button -clickable h-text-align-center clearfix">
									{placeHolderButton}
									{searchInAllModels}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

ActorPlaceholderTile.propTypes = {
	gridCols:          PropTypes.number.isRequired,
	placeholderCols:   PropTypes.number.isRequired,
	actorName:         PropTypes.string.isRequired,
	actorId:           PropTypes.number.isRequired,
	guestIsLoggedIn:   PropTypes.bool.isRequired,
	onResetClickFn:    PropTypes.func,
	textMissing:       PropTypes.string.isRequired,
	searchTags:        PropTypes.arrayOf(PropTypes.string),
	routes:            PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	Link:              PropTypes.object,
	isMobile:          PropTypes.bool,
	hasVideos:         PropTypes.bool,
	gridTileClassName: PropTypes.string,
};

export default ActorPlaceholderTile;
