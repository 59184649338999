import React     from 'react';
import PropTypes from 'prop-types';
import Flux      from '../../../flux/Flux';

const AlbumPinButtonSmall = (props) =>
	Flux.Guest.isLoggedIn() ?
		<div className={'actor-pictures__icon--title -inline icon' + (props.isAlbumFavorite ? ' -icon-pin-full' : ' -icon-pin-line')}
		     onClick={props.onClick}
		/> : null
;

AlbumPinButtonSmall.propTypes = {
	isAlbumFavorite: PropTypes.bool,
	onClick:         PropTypes.func,
};

export default AlbumPinButtonSmall;
