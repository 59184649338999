/* globals VXConfig */
import React                from 'react';
import PropTypes            from 'prop-types';
import Footer               from './Footer';
import VideoPreview         from './VideoPreview';
import CloseButton          from '../Conversion/CloseButton';
import EnumAlbumButtonsType from '../ActorProfile/ActorProfileVideos/AlbumTypeButtons/EnumAlbumButtonsType';

const VideoPreviewEndedVipBox = (props) =>
	<div className={'conversion-box__wrapper ' + (props.isMobile ? '-mobile' : '-pos-abs-center') + props.className}>
		<div className="conversion-box__content-container">
			<CloseButton {...props} />
			<div className="conversion-box__content-row row">
				<div className="col-xs-5 conversion-box__content-left -video-image-teaser">
					<VideoPreview previewImageUrl={props.videoPlayerIncentive.albumPreviewPicture.pictureUrl}>
						<div className="feature-teaser-box__wrapper" />
						{
							VXConfig.isVXMobilePlatform &&
							<div className="badge-container">
								<span className="badge--highlight"><i className="icon -icon-person-vip" /> VIP</span>
							</div>
						}
					</VideoPreview>
				</div>
				<div className="col-xs-7 conversion-box__content-right">
					<div className="feature-teaser-box row">
						<div className="col-xs-12 feature-teaser-box__subheadline h-over-ellipsis"
						     title={props.videoPlayerIncentive.albumTitle}
						     dangerouslySetInnerHTML={{__html: props.videoPlayerIncentive.albumTitle}}
						/>
						<div className="col-xs-12 feature-teaser-box__line" />
						<div className="col-xs-12 feature-teaser-box__vipheadline h-text-uppercase">
							<strong>VIP mitglied</strong> werden <br /> und <strong>Video</strong> in voller<br /> <strong>Länge ansehen!</strong>
						</div>
						<div className="col-xs-12 feature-teaser-box__text -text-vip">
							Werde jetzt VIP und erhalte Zugriff auf noch mehr kostenfreie Clips und weitere exklusive Inhalte!
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer currentVideo={props.currentVideo}
		        videoPlayerIncentive={props.videoPlayerIncentive}
		        type={EnumAlbumButtonsType.vip}
		/>
	</div>;


VideoPreviewEndedVipBox.propTypes = {
	className:            PropTypes.string,
	isMobile:             PropTypes.bool,
	onCloseFn:            PropTypes.func,
	showCloseButton:      PropTypes.bool,
	videoPlayerIncentive: PropTypes.shape({
		albumId:             PropTypes.number.isRequired,
		albumTitle:          PropTypes.string.isRequired,
		albumPreviewPicture: PropTypes.object.isRequired,
	}).isRequired,
	currentVideo:         PropTypes.object,
};

VideoPreviewEndedVipBox.defaultProps = {
	className:       '',
	isMobile:        false,
	onCloseFn:       null,
	showCloseButton: false,
};

export default VideoPreviewEndedVipBox;
