import React                           from 'react';
import PropTypes                       from 'prop-types';
import Translations                    from '../../utils/Translations';
import Flux                            from '../../flux/Flux';
import {VXPay, VXPayAction}            from '../../utils/VXPay';
import {formatChatPrice, formatDateHM} from '../../utils/CommonUtils';
import GuestAccess                     from '../../utils/GuestAccess';
import ReactTooltip                    from 'react-tooltip';
import GlobalEventHandler              from '../../utils/GlobalEventHandler';
import {ModalBoxEnum, openModalBox}    from '../ModalBox/ModalBoxFactory';
import ReloadHelper                    from "../../utils/ReloadHelper";
import { TYPES }                       from '../Loader/Loader';
import { trackLivechatButtonClick }    from "../../utils/GAHelper";
import { getCommonTranslation }        from '../../utils/TranslationHelper';
let closeModalboxFn;

function getCumulativeOffset(element) {
	let top = 0, left = 0;
	do {
		top += element.offsetTop || 0;
		left += element.offsetLeft || 0;
		element = element.offsetParent;
	} while (element);

	return {
		top:  top,
		left: left,
	};
}

class VideochatButton extends React.Component {

	static renderModalBox(target, actorId) {
		const offsetButton = getCumulativeOffset(target);

		let offsetTop, offsetLeft, useFixed;
		if (offsetButton.top < 150) {
			offsetLeft = offsetButton.left;
			offsetTop  = offsetButton.top + 66;
			useFixed   = true;
		} else {
			offsetLeft = offsetButton.left - 10;
			offsetTop  = offsetButton.top - 240;
			useFixed   = false;
		}

		const actorData = Flux.Actor.getActorData(actorId);
		closeModalboxFn = openModalBox(ModalBoxEnum.CONVERSION_CHAT, {showTop: useFixed, actorName: actorData ? actorData.name : 'Model'});

		// re-position the modalbox
		const elBox = document.querySelector('.conversion-chat-box-container');

		elBox.style.left = offsetLeft + 'px';
		elBox.style.top  = offsetTop + 'px';

		const elModal = document.querySelector('.modal');
		if (useFixed) {
			elModal.classList.remove('-static');
		} else {
			elModal.classList.add('-static');
		}
	}

	constructor(props) {
		super(props);

		this.state = {
			isVoicecallActive:   Flux.Guest.isVoicecallActive(),
			isActorOnline:       props.isActorOnline,
			freeChat:            props.freeChat,
			isFreeChatAvailable: this.isFreeChatAvailable(),
			freeChatDuration:    Flux.Guest.getFreeChatDuration(this.props.actorId),
			chatPrice:           props.multiChatPrice,
		};

		this.isFreeChatAvailable          = this.isFreeChatAvailable.bind(this);
		this.onStartVideoChatClick        = this.onStartVideoChatClick.bind(this);
		this.onNotificationChanged        = this.onNotificationChanged.bind(this);
		this.isConversionChatAvailable    = this.isConversionChatAvailable.bind(this);
		this.isConversionChatNotAvailable = this.isConversionChatNotAvailable.bind(this);
		this.onMouseEnter                 = this.onMouseEnter.bind(this);
		this.onFreeChatDurationChange     = this.onFreeChatDurationChange.bind(this);

		this.isUnmounting = false;

		this.buttonRef = null;
	}

	componentDidMount() {
		Flux.Guest.addFreeChatDurationChangeListener(this.onFreeChatDurationChange);
		if (this.state.freeChatDuration === null) {
			Flux.Guest.loadFreeChatDuration(this.props.actorId);
		}

		Flux.Notification.addNotificationChangeListener(this.onNotificationChanged);
		GlobalEventHandler.addListener(GlobalEventHandler.FREE_SHOW_STOP, this.onNotificationChanged);
		ReactTooltip.rebuild();
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.actorId !== nextProps.actorId) {
			this.setState({
				chatPrice:         nextProps.multiChatPrice,
			});
		} else if (this.props.multiChatPrice !== nextProps.multiChatPrice) {
			this.setState({
				chatPrice: nextProps.multiChatPrice,
			});
		}
	}

	componentWillUnmount() {
		this.isUnmounting = true;
		Flux.Special.hideLoader();

		Flux.Guest.removeFreeChatDurationChangeListener(this.onFreeChatDurationChange);
		Flux.Notification.removeNotificationChangeListener(this.onNotificationChanged);
		GlobalEventHandler.removeListener(GlobalEventHandler.FREE_SHOW_STOP, this.onNotificationChanged);

		if (closeModalboxFn) {
			closeModalboxFn();
		}
	}

	onFreeChatDurationChange(actorId) {
		if (actorId === null) {
			Flux.Guest.loadFreeChatDuration(this.props.actorId);
		} else if (actorId === this.props.actorId) {
			this.setState({freeChatDuration: Flux.Guest.getFreeChatDuration(this.props.actorId)});
		}
	}

	isConversionChatAvailable() {
		return this.props.isActorAvailableForConversionChat && Flux.Guest.isEligibleForConversionChat() && !Flux.Guest.isVoicecallActive();
	}

	isConversionChatNotAvailable() {
		return !this.props.isVoyeurMode && this.props.isActorNotAvailableForConversionChat && Flux.Guest.isLoggedIn() && Flux.Guest.getBalance() <= 0 && Flux.Guest.isEligibleForConversionChat() && !Flux.Guest.isVoicecallActive();
	}

	isFreeChatAvailable() {
		return this.props.freeChat && Flux.Guest.isLoggedIn() && (!this.props.freeChat.isVipOnly || Flux.Guest.isVip());
	}

	onMouseEnter(e) {
		e.preventDefault();

		// only on button
		if (e.target && e.target.tagName && e.target.tagName.toUpperCase() === 'BUTTON' && this.isConversionChatNotAvailable()) {
				VideochatButton.renderModalBox(e.target, this.props.actorId);
			}
	}

	onStartVideoChatClick(e) {
		e.preventDefault();

		if (this.props.gaString) {
			trackLivechatButtonClick(this.props.gaString);
		} 

		if (this.props.isBannedCountryCode) {
			ReloadHelper.reload(this.props.actorLink);
			return false;
		}

		if (this.props.isCurrentlyTicketShow && !this.props.guestHasTicket) {
			e.stopPropagation();

			Flux.Messenger.unselectChannel();

			// clear auto open
			Flux.Messenger.clearChannel();

			ReloadHelper.reload(this.props.actorLink + 'live-show/');
			return false;
		}

		ReactTooltip.hide();

		// callback onBeforeClick
		if (typeof this.props.onBeforeClick === 'function') {
			this.props.onBeforeClick();
		}

		// special logic for sender landing page: only redirect to profile instead of really executing an action (hard redirect also for mobile!)
		if (this.props.profileLink && this.props.redirectOnProfileLink) {
			if (Flux.Guest.isLoggedIn()) {
				window.location.href = this.props.profileLink;
			} else {
				VXPay.openSignupOrLogin({},
					new VXPayAction(Flux.Constants.ActionTypes.VXPay.REDIRECT_TO, {
						targetUrl: this.props.profileLink,
				}));
			}
		} else {
			const voucherChatMinutes = (this.props.isVoyeurMode || !this.state.freeChatDuration) ? 0 : this.state.freeChatDuration * 60;
			GuestAccess.onEnoughMoneyForChat(this.props.actorId, this.state.chatPrice, () => {
				const isAnonymousVoicecall = this.state.isVoicecallActive && !Flux.Guest.isLoggedIn();
				if (isAnonymousVoicecall) {
					Flux.NewMessenger.startAnonymousVoicecallChat(this.props.actorId);
					this.props.loaderType && Flux.Special.showLoader(this.props.loaderType);
				} else {
					this.props.loaderType && Flux.Special.showLoader(this.props.loaderType);
					if (this.props.isVoyeurMode) {
						Flux.NewMessenger.startChat(Flux.Constants.VXMessenger.ChatTypes.VOYEUR_CHAT, this.props.actorId);
					} else {
						if (this.state.freeChatDuration > 0) {
							Flux.NewMessenger.startChat(Flux.Constants.VXMessenger.ChatTypes.FREECHAT, this.props.actorId);
						} else {
							Flux.NewMessenger.startChat(Flux.Constants.VXMessenger.ChatTypes.LIVECHAT, this.props.actorId);
						}
					}
				}
			}, this.props.profileLink, voucherChatMinutes);
		}

		if (typeof e === 'object' && e.cancelable) {
			e.preventDefault();
			e.stopPropagation();
		}
	}

	onNotificationChanged(notification) {
		// leave function in order to avoid react warnings (setting state of unmounted component); matsch
		if (this.isUnmounting) {
			return false;
		}

		const NotificationTypes = Flux.Constants.ApiTypes.Notification.Incoming;

		switch (notification.type) {
			case NotificationTypes.VOICECALL_START :
				if (Flux.Guest.isLoggedIn()) {
					this.setState({
						isVoicecallActive: true,
					});
				}
				break;
			case NotificationTypes.VOICECALL_STOP :
				if (Flux.Guest.isLoggedIn()) {
					this.setState({
						isVoicecallActive: false,
					});
				}
				break;
			case NotificationTypes.FREE_SHOW_START:
				if (notification.actorId === this.props.actorId) {
					this.setState({
						freeChat:            notification.data,
						isFreeChatAvailable: this.isFreeChatAvailable(),
					});
				}
				break;
			case NotificationTypes.FREE_SHOW_STOP:
				if (notification.actorId === this.props.actorId) {
					this.setState({
						freeChat:            null,
						isFreeChatAvailable: false,
					});
				}
				break;
			case NotificationTypes.ACTOR_ONLINE:
				if (notification.actorId === this.props.actorId) {
					this.setState({
						isActorOnline: true,
						chatPrice:     this.props.isVoyeurMode ? notification.voyeurChatPrice : notification.multiChatPrice,
					});
				}
				break;
			case NotificationTypes.ACTOR_OFFLINE:
				if (notification.actorId === this.props.actorId) {
					this.setState({
						isActorOnline: false,
					});
				}
				break;
			default:
		}
	}

	getOnlineMobileVideocallButton(className) {
		className.push('-btn-color-videocall');
		className.push('-icon-private-videocall-filled');
		className.push('btn--icon-detailed--videocall');
		
		if (this.props.additionalClassNames !== '') {
			className.push(this.props.additionalClassNames);
		}
		
		return <button className={className.join(' ')}
			onClick={this.onStartVideoChatClick}
			data-tip={getCommonTranslation('videocall-tooltip')}
			data-place="top"
			onMouseEnter={this.onMouseEnter}
			ref={ref => this.buttonRef = ref}
			>
			<span>
				<span className="btn--icon-detailed__text h-text-nowrap">{getCommonTranslation('videocall-button__text')}</span>
				<span className="btn--icon-detailed__teaser h-text-nowrap">{Flux.Guest.isLoggedIn() ? formatChatPrice(this.state.chatPrice) : getCommonTranslation('videocall-button__start')}</span>
			</span>
		</button>;
	}

	render() {
		const className   = ['btn--icon-detailed'];

		if (this.props.isOnlineMobileVideocall && !Flux.Guest.isVoicecallActive()) {
			return this.getOnlineMobileVideocallButton([...className]);
		}

		let iconClassName = '';
		if (this.props.isVoyeurMode === true) {
			iconClassName = ' -icon-voyeur-full';
			if (!this.props.useStandaloneIcon) {
				className.push(iconClassName);
			}
		} else {
			iconClassName = ' -icon-webcam-single-full';
			if (this.state.isFreeChatAvailable) {
				className.push('-btn-color-special');
			} else {
				className.push('-btn-color-success');
			}
			if (!this.props.useStandaloneIcon) {
				className.push(iconClassName);
			}
		}
		if (this.props.additionalClassNames !== '') {
			className.push(this.props.additionalClassNames);
		}
		if (this.state.isFreeChatAvailable) {
			className.push('-free-show');
			if (this.props.isVoyeurMode === true) {
				className.push('h-hide');
			}
		}
		if (!this.state.isActorOnline) {
			className.push('h-hide');
		}

		let buttonText = this.props.buttonText;
		if (this.state.isFreeChatAvailable) {
			buttonText = Translations.get('ChatButtonStartFreeShow');
		} else {
			buttonText = (this.props.isVoyeurMode === true) ? Translations.get('ChatButtonStartVoyeur') : Translations.get('ChatButtonStartVideo');
		}

		let buttonSubline = this.props.buttonSubline;
		if (this.state.isFreeChatAvailable) {
			buttonSubline = Translations.get('ChatButtonFreeShowTime',
				formatDateHM(new Date(this.state.freeChat.begin * 1000)),
				formatDateHM(new Date(this.state.freeChat.end * 1000)));
		} else {
			buttonSubline = (this.props.isVoyeurMode === true) ? Translations.get('ChatButtonStartVoyeurSubline') : Translations.get('ChatButtonStartVideoSubline');
		}

		let chatPriceFormatted;
		if (!Flux.Guest.isLoggedIn() || this.state.isVoicecallActive || this.state.isFreeChatAvailable || (this.state.freeChatDuration > 0 && this.props.isVoyeurMode === false)) {
			chatPriceFormatted = buttonSubline;
		} else {
			chatPriceFormatted = formatChatPrice(this.state.chatPrice);
		}

		if (this.props.isActorAvailableForConversionChat && Flux.Guest.isEligibleForConversionChat()) {
			chatPriceFormatted = Translations.get('ChatButtonFree');
		}

		if (this.props.cssModifier) {
			className.push(this.props.cssModifier);
		}

		const wrapText = this.props.isTextWrapped || this.props.useStandaloneIcon;

		if (this.props.isCurrentlyTicketShow) {
			buttonText = 'LIVE SHOW';
			if (this.props.guestHasTicket) {
				chatPriceFormatted = Translations.get('LiveShowButtonJoin');
			} else {
				chatPriceFormatted = Translations.get('LiveShowButtonTakePart');
			}
		}

		return (
			<button className={className.join(' ')}
			        onClick={this.onStartVideoChatClick}
			        data-tip={this.props.tooltipText}
			        data-place="top"
			        onMouseEnter={this.onMouseEnter}
					ref={ref => this.buttonRef = ref}
			>
				<span className={wrapText ? 'btn__text-container' : ''}>
					{this.props.useStandaloneIcon && <i className={'icon ' + iconClassName} />}
					<span className="btn--icon-detailed__text h-text-nowrap" dangerouslySetInnerHTML={{__html: buttonText}} />
					<span className="btn--icon-detailed__teaser h-text-nowrap">{chatPriceFormatted}</span>
				</span>
			</button>
		);
	}
}

VideochatButton.defaultProps = {
	isVoyeurMode:                         false,
	isVoyeurinSingleMode:                 false,
	additionalClassNames:                 '',
	isActorOnline:                        false,
	freeChat:                             null,
	tooltipText:                          '',
	cssModifier:                          '',
	profileLink:                          '',
	redirectOnProfileLink:                true,
	isTextWrapped:                        false,
	isActorAvailableForConversionChat:    false,
	isActorNotAvailableForConversionChat: false,
	isBannedCountryCode:                  false,
	actorLink:                            '',
	isCurrentlyTicketShow:                false,
	guestHasTicket:                       false,
	loaderType:                           TYPES.DOT,
	gaString:                             '',
	isOnlineMobileVideocall:              false,
};

VideochatButton.propTypes = {
	actorId:                              PropTypes.number.isRequired,
	multiChatPrice:                       PropTypes.number,
	isActorOnline:                        PropTypes.bool,
	isVoyeurMode:                         PropTypes.bool,
	isVoyeurinSingleMode:                 PropTypes.bool,
	additionalClassNames:                 PropTypes.string,
	buttonText:                           PropTypes.string,
	buttonSubline:                        PropTypes.string,
	useStandaloneIcon:                    PropTypes.bool,
	isTextWrapped:                        PropTypes.bool,
	freeChat:                             PropTypes.object,
	tooltipText:                          PropTypes.string,
	onBeforeClick:                        PropTypes.func,
	cssModifier:                          PropTypes.string,
	profileLink:                          PropTypes.string,
	redirectOnProfileLink:                PropTypes.bool,
	voucherChatMinutes:                   PropTypes.number,
	isActorAvailableForConversionChat:    PropTypes.bool,
	isActorNotAvailableForConversionChat: PropTypes.bool,
	isBannedCountryCode:                  PropTypes.bool,
	actorLink:                            PropTypes.string,
	isCurrentlyTicketShow:                PropTypes.bool,
	guestHasTicket:                       PropTypes.bool,
	loaderType:                           PropTypes.string,
	gaString:                             PropTypes.string,
	isOnlineMobileVideocall:              PropTypes.bool,
};

export default VideochatButton;
