import React        from 'react';
import PropTypes    from 'prop-types';
import Translations from '../../../../utils/Translations';

const AlbumButtonAvs = (props) =>
	<div className={`${props.baseClassName}__button--buy`} onClick={props.onClick}>
		<div className={`${props.baseClassName}__icon--button icon -icon-keylock_open`} />
		<div className={`${props.baseClassName}__text--button`}>{Translations.get('AvsTeaserConfirmationButton')}</div>
	</div>
;

AlbumButtonAvs.propTypes = {
	onClick:       PropTypes.func,
	baseClassName: PropTypes.string,
};

export default AlbumButtonAvs;
