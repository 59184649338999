/* globals VXConfig */
import React                       from 'react';
import PropTypes                   from 'prop-types';
import Flux                        from '../../../flux/Flux';
import {VXPay}                     from '../../../utils/VXPay';
import GuestAccess                 from '../../../utils/GuestAccess';
import GlobalEventHandler          from '../../../utils/GlobalEventHandler';
import ReloadHelper                from '../../../utils/ReloadHelper';
import AlbumButtonAvs              from './AlbumTypeButtons/AlbumButtonAvs';
import AlbumButtonVoucher          from './AlbumTypeButtons/AlbumButtonVoucher';
import AlbumButtonBuy              from './AlbumTypeButtons/AlbumButtonBuy';
import AlbumButtonBought           from './AlbumTypeButtons/AlbumButtonBought';
import AlbumButtonVip              from './AlbumTypeButtons/AlbumButtonVip';
import AlbumButtonContest          from './AlbumTypeButtons/AlbumButtonContest';
import AlbumButtonBonus            from './AlbumTypeButtons/AlbumButtonBonus';
import EnumAlbumButtonsType        from './AlbumTypeButtons/EnumAlbumButtonsType';
import EnumAnalyticsEvents         from '../../../utils/EnumAnalyticsEvents';
import {gaHelper, gtagHelper}      from '../../../utils/CommonUtils';
import EnumGA4Events               from '../../../utils/EnumGA4Events';
import {openVip30Modal}            from "../../ModalBox/ModalBoxFactory";
import {openModalBox}              from "../../ModalBox/ModalBoxFactory";
import {COLOR_TYPE_CALL_TO_ACTION} from "../../SimpleElements/VXButton/ButtonHelper";
import {VXButtonLineConfig}        from "../../SimpleElements/VXButton/VXButtonConfig";
import {getCommonTranslation}      from "../../../utils/TranslationHelper";
import STYLES                      from "../../SimpleElements/VXButton/EnumVXButton";
import VXButton                    from "../../SimpleElements/VXButton/VXButton";
import Constants                   from '../../../flux/Constants.js';

class AlbumBuyButtons extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			onVideoBuySuccess: false,
			videoVoucherCount: Flux.Guest.getVoucherCountByType(Flux.Constants.VoucherTypes.TYPE_VIDEO, this.props.actorDataId),
			isIncentiveShown:        false,
		};

		this.buyAlbum           = this.buyAlbum.bind(this);
		this.buyAlbumWithModal  = this.buyAlbumWithModal.bind(this);
		this.onVipClick         = this.onVipClick.bind(this);
		this.onContestClick     = this.onContestClick.bind(this);
		this.openLogin          = this.openLogin.bind(this);
		this.openAVS            = this.openAVS.bind(this);
		this.onBonusClick       = this.onBonusClick.bind(this);
		this.reloadVoucherCount = this.reloadVoucherCount.bind(this);
		this.onIncentivesChange = this.onIncentivesChange.bind(this);
		this.onIncentiveClose   = this.onIncentiveClose.bind(this);
	}

	componentDidMount() {
		Flux.Guest.addGuestChangeListener(this.reloadVoucherCount);
		Flux.Conversion.addIncentivesChangeListener(this.onIncentivesChange);
		Flux.Conversion.addIncentiveCloseListener(this.onIncentiveClose);
	}

	componentDidUpdate(prevProps) {
		if (this.props.actorDataId !== prevProps.actorDataId) {
			this.reloadVoucherCount();
		}
	}

	componentWillUnmount() {
		Flux.Guest.removeGuestChangeListener(this.reloadVoucherCount);
		Flux.Conversion.removeIncentivesChangeListener(this.onIncentivesChange);
		Flux.Conversion.removeIncentiveCloseListener(this.onIncentiveClose);
	}

	reloadVoucherCount() {
		this.setState({
			videoVoucherCount: Flux.Guest.getVoucherCountByType(Flux.Constants.VoucherTypes.TYPE_VIDEO, this.props.actorDataId),
		});
	}

	onVipClick(e) {
		openVip30Modal();

		e.preventDefault();
		e.stopPropagation();
		gaHelper('send', 'event', EnumAnalyticsEvents.CATEGORY_CONVERSION, EnumAnalyticsEvents.OPEN_VIP_CLICK);
		gtagHelper(EnumGA4Events.DEFAULT_PARAMETER_EVENT, EnumGA4Events.CUSTOM_EVENT_CONVERSION, {
			[EnumGA4Events.CUSTOM_PARAMETER_CONVERSION]: EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_OPEN_VIP_CLICK,
		});
	}

	openLogin() {
		VXPay.openLogin();
		gaHelper('send', 'event', EnumAnalyticsEvents.CATEGORY_CONVERSION, EnumAnalyticsEvents.OPEN_SIGNUP_CLICK);
		gtagHelper(EnumGA4Events.DEFAULT_PARAMETER_EVENT, EnumGA4Events.CUSTOM_EVENT_CONVERSION, {
			[EnumGA4Events.CUSTOM_PARAMETER_CONVERSION]: EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_OPEN_SIGNUP_CLICK,
		});
	}

	buttonType() {
		const {
			      canBuy,
			      isAlbumBought,
			      isAlbumBoughtInitial,
			      needAvs,
			      needVip,
			      voucherPrice,
			      isFromCurrentVideoContest,
			      isVip30,
			      needBuying,
			      price,
			      needPayment,
		      } = this.props;
		if (!Flux.Guest.isLoggedIn()) {
			return EnumAlbumButtonsType.login;
		}

		if (!Flux.Guest.isLoggedIn() && !Flux.Guest.isAnonymousVoicecall()) {
			return null;
		}

		const canVoucher = voucherPrice > 0 && this.state.videoVoucherCount >= voucherPrice;
		if (needAvs && !needVip && ((Flux.Guest.getBalance() >= price || canVoucher) || (!needBuying && !needPayment) || isVip30)) {
			return EnumAlbumButtonsType.avs;
		}

		if (canBuy || needBuying) {
			if (VXConfig.isVXMobilePlatform && isVip30 && !Flux.Guest.isVip()) {
				return EnumAlbumButtonsType.vip30;
			}
			if (canVoucher) {
				return EnumAlbumButtonsType.withVoucher;
			}

			return EnumAlbumButtonsType.buy;
		}

		if (isAlbumBought && !isAlbumBoughtInitial && this.state.onVideoBuySuccess) {
			return EnumAlbumButtonsType.bought;
		}

		if (!Flux.Guest.isVip() && needVip) {
			return EnumAlbumButtonsType.vip;
		}

		if (isFromCurrentVideoContest) {
			return EnumAlbumButtonsType.contest;
		}

	}

	openAVS() {
		VXPay.openAVS();
		gaHelper('send', 'event', EnumAnalyticsEvents.CATEGORY_CONVERSION, EnumAnalyticsEvents.OPEN_AVS_CLICK);
	}

	buyAlbumWithModal() {
		openModalBox(this.props.onBuyModalId, {callback: this.buyAlbum, id: this.props.id});
	}

	buyAlbum(modalCallback) {
		const {actorDataId, needAvs, needVip, isAccessAllowed, price, voucherPrice, id, isVip30} = this.props;
		gaHelper('send', 'event', EnumAnalyticsEvents.CATEGORY_CONVERSION, EnumAnalyticsEvents.PURCHASE_VIDEO_CLICK);
		gtagHelper(EnumGA4Events.DEFAULT_PARAMETER_EVENT, EnumGA4Events.CUSTOM_EVENT_CONVERSION, {
			[EnumGA4Events.CUSTOM_PARAMETER_CONVERSION]: EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_PURCHASE_VIDEO_CLICK,
		});
		if ((!isAccessAllowed || isVip30) && price) {
			const canVoucher = voucherPrice > 0 && this.state.videoVoucherCount >= voucherPrice;

			const albumId = id;
			GuestAccess.onEnoughMoneyForShop(
				actorDataId,
				price,
				needAvs,
				needVip,
				() => {
					const clb = (actorId, changedAlbumId) => {
						if (changedAlbumId === albumId) {
							Flux.Gallery.removeGalleryChangeListener(clb);

							if (typeof this.props.onBuySuccess === "function") {
								this.props.onBuySuccess();
							}

							// reload gallery - if necessary
							if (VXConfig.showPrivateShopDiscountBanner) {
								Flux.Search.clearCache(['videos', 'photos']);
								ReloadHelper.reloadCurrentPage();
							}
							if (typeof modalCallback === 'function') {
								modalCallback();
							}

							// enable input comment
							Flux.Gallery.enableFeedback();
						}
					};

					Flux.Gallery.addGalleryChangeListener(clb);
					Flux.Gallery.buyAlbum(albumId);
					GlobalEventHandler.emit(GlobalEventHandler.LOADER_TOGGLE, true);
					this.setState({onVideoBuySuccess: true});
					setTimeout(() => this.setState({onVideoBuySuccess: false}), 3000);

					gaHelper('send', 'event', EnumAnalyticsEvents.CATEGORY_CONVERSION, EnumAnalyticsEvents.PURCHASE_VIDEO_SUCCESS);
					gtagHelper(EnumGA4Events.DEFAULT_PARAMETER_EVENT, EnumGA4Events.CUSTOM_EVENT_CONVERSION, {
						[EnumGA4Events.CUSTOM_PARAMETER_CONVERSION]: EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_PURCHASE_VIDEO_CLICK,
					});

					if (typeof this.props.onBuy === "function") {
						this.props.onBuy();
					}

				},
				'undefined',
				canVoucher
			);
		}
	}

	onContestClick(e) {
		ReloadHelper.reload(this.props.contestLink, e);
	}

	onBonusClick(e) {
		e.preventDefault();
		VXPay.openPaytour({
			option: this.props.bonusCode,
		});
		gaHelper('send', 'event', EnumAnalyticsEvents.CATEGORY_CONVERSION, EnumAnalyticsEvents.OPEN_SALE_CLICK);
		gtagHelper(EnumGA4Events.DEFAULT_PARAMETER_EVENT, EnumGA4Events.CUSTOM_EVENT_CONVERSION, {
			[EnumGA4Events.CUSTOM_PARAMETER_CONVERSION]: EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_MONEYCHARGE_CLICK,
		});
		VXPay.onMoneyChargeSuccess(() => {
			gaHelper('send', 'event', EnumAnalyticsEvents.CATEGORY_CONVERSION, EnumAnalyticsEvents.SALE_SUCCESS);
			gtagHelper(EnumGA4Events.DEFAULT_PARAMETER_EVENT, EnumGA4Events.CUSTOM_EVENT_CONVERSION, {
				[EnumGA4Events.CUSTOM_PARAMETER_CONVERSION]: EnumGA4Events.CUSTOM_PARAMETER_CONVERSION__VALUE_MONEYCHARGE_SUCCESS,
			});
		});
	}

	onIncentivesChange(type) {
		if (type === Constants.ConversionEventTypes.VIDEO_PREVIEW_ENDED) {
			this.setState({isIncentiveShown: true});
		}
	}

	onIncentiveClose() {
		this.setState({isIncentiveShown: false});
	}

	render() {
		const {isVip30, voucherPrice, baseClassName, type, hasActiveDiscount, crossPrice, price, id, buttonSize} = this.props;
		let albumActionButton;

		switch (type ? type : this.buttonType()) {
			case EnumAlbumButtonsType.avs:
				albumActionButton = (
					<AlbumButtonAvs baseClassName={baseClassName}
					                onClick={this.openAVS}
					/>
				);
				break;
			case EnumAlbumButtonsType.bonus:
				albumActionButton = (
					<AlbumButtonBonus baseClassName={baseClassName}
					                  onClick={this.onBonusClick}
					/>
				);
				break;
			case EnumAlbumButtonsType.withVoucher:
				albumActionButton = (
					<AlbumButtonVoucher baseClassName={baseClassName}
					                    voucherPrice={voucherPrice}
					                    onClick={this.props.onBuyModalId ? this.buyAlbumWithModal : this.buyAlbum}
                                        isVip30={isVip30}
					/>
				);
				break;
			case EnumAlbumButtonsType.buy:
				albumActionButton = (
					<AlbumButtonBuy baseClassName={baseClassName}
					                onClick={this.props.onBuyModalId ? this.buyAlbumWithModal : this.buyAlbum}
					                isVip30={isVip30}
					                hasActiveDiscount={hasActiveDiscount}
					                crossPrice={crossPrice}
					                price={price}
					                id={id}
					/>
				);
				break;
			case EnumAlbumButtonsType.bought:
				albumActionButton = <AlbumButtonBought baseClassName={baseClassName} />;
				break;
			case EnumAlbumButtonsType.vip:
				albumActionButton = (
					<AlbumButtonVip baseClassName={baseClassName}
					                onClick={this.onVipClick}
					/>
				);
				break;
			case EnumAlbumButtonsType.contest:
				albumActionButton = (
					<AlbumButtonContest baseClassName={baseClassName}
					                    onClick={this.onContestClick}
					/>
				);
				break;
			case EnumAlbumButtonsType.login:
				if (this.props.showSignupButton) {
					albumActionButton = (
						<VXButton color={COLOR_TYPE_CALL_TO_ACTION}
						          line1={[new VXButtonLineConfig(getCommonTranslation('login_or_signup_button').toUpperCase())]}
						          onClick={this.openLogin}
						          size={buttonSize ? buttonSize : STYLES.SIZE.SMALL}
						          overrideStyles={this.props.vxbuttonAdditionalStyle}
						/>
					);
				}
				break;
			case EnumAlbumButtonsType.vip30:
				this.props.children = [];
				albumActionButton   = (
					<div style={{display: "flex", width: "100%", justifyContent: "space-around"}}>
						<span className={`${baseClassName}__button--buy--left`} style={{width: "48%"}}>
							<AlbumButtonBuy baseClassName={baseClassName}
							                onClick={this.props.onBuyModalId ? this.buyAlbumWithModal : this.buyAlbum}
							                hasActiveDiscount={hasActiveDiscount}
							                crossPrice={crossPrice}
							                price={price}
							                isVip30={isVip30}
							                id={id}
							/>
						</span>
						<span style={{width: "48%"}}>
							<span className={"btn -btn-color-gold -vip30-mobile-floating"}
							      onClick={this.onVipClick}
							>
								<span className={"icon -icon-person-vip"} />kostenlos als VIP
							</span>
						</span>
					</div>
				);
				break;
			default:
				albumActionButton = null;
		}

		albumActionButton = this.state.isIncentiveShown && VXConfig.isVXMobilePlatform ? null : albumActionButton;

		return (VXConfig.isVXMobilePlatform && albumActionButton !== null && this.props.sticky ?
			<div className={"sticky-button__background -bottomNav sticky-button__background--show"}>
				<div className={`${baseClassName}__button--buy-wrapper`}>
					{albumActionButton}
					{!!albumActionButton && this.props.children}
				</div>
			</div> : <div className={`${baseClassName}__button--buy-wrapper`}>
				{albumActionButton}
				{!!albumActionButton && this.props.children}
			</div>);
	}
}

AlbumBuyButtons.propTypes = {
	actorDataId:               PropTypes.number,
	isAlbumBoughtInitial:      PropTypes.bool,
	isAlbumBought:             PropTypes.bool,
	baseClassName:             PropTypes.string,
	type:                      PropTypes.string,
	isFromCurrentVideoContest: PropTypes.bool,
	needVip:                   PropTypes.bool,
	hasActiveDiscount:         PropTypes.bool,
	isAccessAllowed:           PropTypes.bool,
	needPayment:               PropTypes.bool,
	needBuying:                PropTypes.bool,
	needAvs:                   PropTypes.bool,
	price:                     PropTypes.number,
	crossPrice:                PropTypes.number,
	voucherPrice:              PropTypes.number,
	id:                        PropTypes.number,
	contestLink:               PropTypes.string,
	bonusCode:                 PropTypes.string,
	canBuy:                    PropTypes.bool,
	isVip30:                   PropTypes.bool,
	onBuyModalId:              PropTypes.string,
	buttonSize:                PropTypes.string,
	showSignupButton:          PropTypes.bool,
	vxbuttonAdditionalStyle:   PropTypes.object,
	onBuy:                     PropTypes.func,
	onBuySuccess:              PropTypes.func,
	sticky:                    PropTypes.bool,
	children:                  PropTypes.any,
};

AlbumBuyButtons.defaultProps = {
	showSignupButton: true,
	onBuy:            null,
	sticky:           true, // todo implement properly
};

export default AlbumBuyButtons;
