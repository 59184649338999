/* global VXConfig */

import React                       from 'react';
import PropTypes                   from 'prop-types';
import ReactDOM                    from 'react-dom';
import Flux                        from './../../flux/Flux';
import Modal                       from '../Modal';
import ViewerControls              from './ViewerControls';
import Translations                from './../../utils/Translations';
import ActorPin                    from './../Favorite/Actor/Pin';
import MediaPin                    from './../Favorite/Actor/MediaPin';
import Like                        from './../Like';
import Dislike                     from './../Dislike';
import PjaxWrapper                 from '../../utils/PjaxWrapper';
import TranslationHelper           from '../../utils/TranslationHelper';
import ContestVoting               from './../ContestVoting';
import GlobalEventHandler          from './../../utils/GlobalEventHandler';
import ReloadHelper                from '../../utils/ReloadHelper';
import VXButton                    from "../SimpleElements/VXButton/VXButton";
import {COLOR_TYPE_CALL_TO_ACTION} from "../SimpleElements/VXButton/ButtonHelper";
import {VXButtonLineConfig}        from "../SimpleElements/VXButton/VXButtonConfig";
import {VXPay}                     from '../../utils/VXPay';
import {getCommonTranslation}      from '../../utils/TranslationHelper';
import {addClass, removeClass}     from "../../utils/CommonUtils";

class Viewer extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			images:               this.props.initialImages,
			isControlsHidden:     false,
			isModelGuestFavorite: this.props.initialIsFavoriteOfGuest,
			selectedIndex:        this.props.initialImages && (typeof this.props.initialImages[this.props.selectedIndex] !== 'undefined') ? this.props.selectedIndex : 0,
			showModal:            this.props.initShowModal,
			isLoggedIn:           Flux.Guest.isLoggedIn(),
		};

		this.imageRef   = null;
		this.touchState = null;

		this.updateVotes        = this.updateVotes.bind(this);
		this.shouldVotesUpdate  = this.shouldVotesUpdate.bind(this);
		this.onFavoriteChange   = this.onFavoriteChange.bind(this);
		this.onPicturePinStatus = this.onPicturePinStatus.bind(this);
		this.onPictureChange    = this.onPictureChange.bind(this);
		this.onChange           = this.onChange.bind(this);
		this.onModalClose       = this.onModalClose.bind(this);
		this.onSelect           = this.onSelect.bind(this);
		this.onShowModal        = this.onShowModal.bind(this);
		this.updateIndex        = this.updateIndex.bind(this);
		this.updateIndexMobile  = this.updateIndexMobile.bind(this);
		this.showModal          = this.showModal.bind(this);
		this.hideModal          = this.hideModal.bind(this);
		this.onImageClick       = this.onImageClick.bind(this);
		this.onImageLoad        = this.onImageLoad.bind(this);
		this.move               = this.move.bind(this);
		this.onTouchEnd         = this.onTouchEnd.bind(this);
		this.onTouchMove        = this.onTouchMove.bind(this);
		this.onTouchStart       = this.onTouchStart.bind(this);
		this.onLinkClick   		= this.onLinkClick.bind(this);

	}

	componentDidMount() {
		Flux.Contest.addContestVotingChangeListener(this.updateVotes);
		Flux.Favorite.addActorPinnedListener(this.onFavoriteChange);
		Flux.Favorite.addActorUnpinnedListener(this.onFavoriteChange);
		Flux.Favorite.addPicturePinStatusListener(this.onPicturePinStatus);
		Flux.Gallery.addPictureRatingChangeListener(this.onPictureChange);

		GlobalEventHandler.addListener(GlobalEventHandler.GALLERY_SHOW_MODAL, this.onShowModal);
		GlobalEventHandler.addListener(GlobalEventHandler.GALLERY_SELECT, this.onSelect);

		if (this.props.initShowModal) {
			this.showModal();
		}

		this.setState({
			isLoggedIn: Flux.Guest.isLoggedIn(),
		});

	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.selectedIndex !== this.props.selectedIndex) {
			this.updateIndex(nextProps.selectedIndex);
		}
		if (nextProps.initialImages !== this.props.initialImages) {
			this.setState({
				images: nextProps.initialImages,
			});
		}
		if (nextProps.initialIsFavoriteOfGuest !== this.props.initialIsFavoriteOfGuest) {
			this.setState({
				isModelGuestFavorite: nextProps.initialIsFavoriteOfGuest,
			});
		}
	}

	componentWillUnmount() {
		Flux.Contest.removeContestVotingChangeListener(this.updateVotes);
		Flux.Favorite.removeActorPinnedListener(this.onFavoriteChange);
		Flux.Favorite.removeActorUnpinnedListener(this.onFavoriteChange);
		Flux.Favorite.removePicturePinStatusListener(this.onPicturePinStatus);
		Flux.Gallery.removePictureRatingChangeListener(this.onPictureChange);

		GlobalEventHandler.removeListener(GlobalEventHandler.GALLERY_SHOW_MODAL, this.onShowModal);
		GlobalEventHandler.removeListener(GlobalEventHandler.GALLERY_SELECT, this.onSelect);
	}

	onFavoriteChange(actorId, status) {
		if (this.props.actor.id === actorId) {
			this.setState({
				isModelGuestFavorite: status,
			});
		}
	}

	onPicturePinStatus(pictureId, status) {
		const images = this.state.images;
		for (let i = 0; i < images.length; i++) {
			if (images[i].id === pictureId) {
				images[i].pinned = status;

				this.setState({
					images: images,
				});
			}
		}
	}

	onPictureChange(pictureId) {
		const images = this.state.images;
		for (let i = 0; i < images.length; i++) {
			if (images[i].id === pictureId) {
				const data = Flux.Gallery.getPictureData(pictureId);

				if (data) {
					images[i].isLiked    = data.isLiked;
					images[i].isDisliked = data.isDisliked;

					this.setState({
						images: images,
					});
				}
			}
		}
	}

	onChange(offset) {
		const calcIndex = (this.state.selectedIndex + offset) % this.state.images.length;
		const currIndex = calcIndex >= 0 ? calcIndex : this.state.images.length + calcIndex;

		if (this.props.isMobile) {
			this.updateIndexMobile(currIndex, offset);
		} else {
			this.updateIndex(currIndex);
		}

		if (typeof this.props.onChange === 'function') {
			this.props.onChange(currIndex);
		}
	}

	onModalClose() {
		this.props.onCloseModalBox();
		this.hideModal();
	}

	onSelect(instanceId, index) {
		if (this.props.instanceId === instanceId) {
			this.updateIndex(index);
		}
	}

	onShowModal(instanceId) {
		if (this.props.instanceId === instanceId) {
			this.showModal();
		}
	}

	updateVotes(guestId, contestId, mediaId) {
		const images       = this.state.images.map((item) => ({...item}));
		const currentImage = images[this.state.selectedIndex];

		if (guestId === currentImage.guestId &&
			contestId === currentImage.contestId &&
			mediaId === currentImage.mediaId
		) {
			currentImage.contestVotes = Flux.Contest.getContestVoting(currentImage.mediaId);
			if (currentImage && currentImage.contestVotes) {
				images[this.state.selectedIndex] = currentImage;

				this.setState({
					images: images,
				});
			}
		}
	}

	updateIndex(index) {
		this.props.onOpenMediaViewer(index);
		const $imgEl = this.imageRef;

		if ($imgEl) {
			$imgEl.classList.remove('gallery-fadein');
			$imgEl.classList.add('gallery-fadeout');
		}

		window.setTimeout(function() {
			this.setState({
				selectedIndex: index,
			}, function() {
				if ($imgEl) {
					$imgEl.classList.remove('gallery-fadeout');
					$imgEl.classList.add('gallery-fadein');
				}
			});
		}.bind(this), 200);
	}

	updateIndexMobile(index, direction) {
		const $imgEl = this.imageRef;

		removeClass($imgEl, 'is-shown');

		// copy current image and append as sibling for current image
		const copy = $imgEl.cloneNode(true);
		$imgEl.parentElement.append(copy);

		// remove current image to make room for the next one
		$imgEl.style.transform       = '';
		$imgEl.style.webkitTransform = '';
		$imgEl.src                   = '';
		removeClass($imgEl, 'is-touch');
		addClass($imgEl, 'is-loading');

		// animate old image
		window.setTimeout(function(direction, copy) {
			copy.transform       = '';
			copy.webkitTransform = '';
			if (direction < 0) {
				addClass(copy, 'is-hidden');
				addClass(copy, 'right');
			} else {
				addClass(copy, 'is-hidden');
				addClass(copy, 'left');
			}
		}.bind(this, direction, copy), 10);

		// add loader
		window.setTimeout(function(imgEl) {
			if (imgEl.classList.contains('is-loading')) {
				addClass(imgEl, 'show-loader');
			}
		}.bind(this, $imgEl), 100);

		// clean up
		window.setTimeout(function() {
			copy.remove();
		}, 500);

		// get new image
		window.setTimeout(function() {
			this.setState({
				selectedIndex: index,
			});
		}.bind(this), 200);
	}

	showModal() {
		if (typeof this.props.onShowModal === 'function') {
			this.props.onShowModal();
			return;
		}

		document.onkeydown = checkKey.bind(this);

		function checkKey(e) {
			e = e || window.event;

			switch (e.keyCode) {
				// escape
				case 27:
					this.hideModal();
					break;
				// back
				case 37:
				case 65:
					this.move(-1);
					break;
				// forward
				case 39:
				case 68:
					this.move(1);
					break;
				default:
			}
		}

		this.touchState = null;
		this.setState({
			showModal: true,
		});
	}

	hideModal() {
		document.onkeydown = null;

		if (this.props.initShowModal) {
			ReactDOM.unmountComponentAtNode(ReactDOM.findDOMNode(this).parentNode);
		} else {
			this.touchState = null;
			this.setState({
				showModal: false,
			});
		}

	}

	onImageClick() {
		if (this.state.showModal) {
			if (this.props.isMobile) {
				this.setState({
					isControlsHidden: !this.state.isControlsHidden,
				});
			} else {
				this.move(1);
			}
		} else {
			this.showModal();
		}
	}

	onImageLoad() {
		this.shouldVotesUpdate();
		if (this.state.showModal && this.props.isMobile) {
			window.setTimeout(function() {
				removeClass(this.imageRef, 'is-loading');
				removeClass(this.imageRef, 'show-loader');
				addClass(this.imageRef, 'is-shown');
			}.bind(this), 25);
		}
	}

	move(offset) {
		if (this.props.initShowModal) {
			this.onChange(offset);
		} else {
			GlobalEventHandler.emit(GlobalEventHandler.GALLERY_MOVE, this.props.instanceId, offset);
		}
	}

	onTouchEnd() {
		if (this.touchState && this.props.isMobile && this.touchState.endX !== null) {
			const diffX = this.touchState.startX - this.touchState.endX;

			if (Math.abs(diffX) > 50) {
				this.onChange(diffX < 0 ? -1 : 1);
			} else {
				this.imageRef.style.transform       = '';
				this.imageRef.style.webkitTransform = '';
				removeClass(this.imageRef, 'is-touch');
				addClass(this.imageRef, 'is-shown');
			}
		}

		this.touchState = null;
	}

	onTouchMove(e) {
		if (this.touchState && this.state.showModal && this.props.isMobile) {
			e.preventDefault();
			e.stopPropagation();

			if (!this.imageRef) {
				return;
			}

			this.touchState.endX = e.touches[0].clientX;
			this.touchState.endY = e.touches[0].clientY;
			let transform        = '';
			if (!Flux.Browser.isSafariMobile() || e.touches.length < 2) { // because vxone allows iOS (but not Android) to zoom into viewport
				const diffX = this.touchState.endX - this.touchState.startX;

				transform = 'translate3d(calc(' + diffX + 'px - 50%), -50%, 0)';
			}

			this.imageRef.style.transform       = transform;
			this.imageRef.style.webkitTransform = transform;
		}
	}

	onTouchStart(e) {
		if (this.state.showModal && this.props.isMobile) {
			this.touchState = {startX: e.touches[0].clientX, startY: e.touches[0].clientY, endX: null, endY: null};

			if (this.imageRef) {
				removeClass(this.imageRef, 'is-shown');
				addClass(this.imageRef, 'is-touch');
			}
		}
	}

	onLinkClick(e) {
		const targetUrl = e.currentTarget.getAttribute('target');
		if (targetUrl) {
			if(this.props.navigateTo) {
				this.props.navigateTo(targetUrl, e);
				this.onModalClose();
			} else {			
				ReloadHelper.reload(targetUrl, e);
			}
		} else {
			if (e.stopPropagation) {
				e.stopPropagation();
			}
			if (e.preventDefault) {
				e.preventDefault();
			}
		}
	}

	shouldVotesUpdate() {
		const currentImage = this.state.images[this.state.selectedIndex];
		if (currentImage && currentImage.contestVotes) {
			this.updateVotes(VXConfig.guestId, currentImage.contestId, currentImage.mediaId);
		}
	}

	render() {
		if (!this.state.images || this.state.images.length <= 0 || (!this.state.showModal && this.props.initShowModal)) {
			return false;
		}

		const currentImage = this.state.images[this.state.selectedIndex];

		let imageUrl;
		if (!this.props.initShowModal) {
			imageUrl = currentImage.url;
		}

		let loader;
		if (this.props.loader) {
			loader = (
				<div className="modal__loader">
					{this.props.loader}
				</div>
			);
		}

		const origUrl = currentImage.origUrl;
		const getRef  = (ref) => {
			this.imageRef = ref;
		};

		let picDom = null;

		if (currentImage.isOver18Content && !Flux.Guest.hasAvs()) {
			const openAvs = () => {
				VXPay.openAVS();
			};

			picDom = (
				<div className="modal__picture-no-avs__wrapper">
					<span className={"icon -icon-keylock_close modal__picture-no-avs__key"}>
						<span className="modal__picture-no-avs__badge">{Translations.get('Over18')}</span>
					</span>
					<div className="modal__picture-no-avs__headline">{getCommonTranslation('modal__no-avs__headline')}</div>
					<div className="modal__picture-no-avs__text">{getCommonTranslation('modal__no-avs__text')}</div>
					<div className="modal__picture-no-avs__button">
						<VXButton line1={[new VXButtonLineConfig(Translations.get('AvsTeaserConfirmationButton'))]}
						          icon={"-icon-keylock_open"}
						          color={COLOR_TYPE_CALL_TO_ACTION}
						          onClick={openAvs}
						/>
					</div>
				</div>
			);
		} else {
			picDom = (
				<a className="h-cursor-pointer h-height-full" onClick={this.onImageClick}>
					<img src={this.state.showModal ? origUrl : imageUrl}
					     className={(!this.state.showModal ? 'gallery__image' : 'modal__picture__image')}
					     ref={getRef}
					     onTouchStart={this.onTouchStart}
					     onTouchEnd={this.onTouchEnd}
					     onTouchMove={this.onTouchMove}
					     onLoad={this.onImageLoad}
					     srcSet={this.props.srcSet}
					/>
					{this.state.showModal && loader}
				</a>
			);
		}


		let descText;
		if (currentImage.descText && this.props.hideVoting && this.props.hideAlbumInfo) {
			if (this.props.actorBaseLink) {
				descText = (
					<div className="gallery__descText">
						{TranslationHelper.getTextWithActorLinks(currentImage.descText, 'gallery__descText--link', null, this.props.actorBaseLink)}
					</div>
				);
			} else {
				descText = (
					<div className="gallery__descText" dangerouslySetInnerHTML={{__html: currentImage.descText}}/>
				);
			}

			if (this.state.showModal) {
				descText = <div className="gallery__descText-modal">{descText}</div>;
			}
		}

		const containerClasses = [];
		if (this.state.showModal) {
			containerClasses.push('h-height-full');
		} else if (descText) {
			containerClasses.push('h-pos-relative');
		}

		const getContent = (
			<div className='h-height-full'>
				<div className={`${containerClasses.join(' ')} h-height-full`}>
					{this.state.showModal ? <div className="modal__picture">{picDom}</div> : picDom}
					<ViewerControls images={this.state.images}
					                instanceId={this.props.instanceId}
					                showModal={this.state.showModal}
					                selectedIndex={this.props.selectedIndex}
					                onChange={this.onChange}
					                isControlsHidden={this.state.isControlsHidden}
					                showControlsNonModal={this.props.showControlsNonModal}
					/>
					{this.state.showModal && <div className={'modal__footer' + (this.state.isControlsHidden ? ' is-hidden' : '')}>
						<div className="h-left">
							{!this.props.hideAlbumInfo &&
								<div>
									<div className="h-color-fg modal__footer-text">
										{currentImage.title &&
											<div className='modal__footer-link' target={currentImage.titleUrl} onClick={this.onLinkClick}>
												<span className="h-text-highlight" dangerouslySetInnerHTML={{__html: currentImage.title}}/>
											</div>}<span
										className="h-text-grey">&nbsp;-&nbsp;{Translations.get('Picture')} {this.state.selectedIndex + 1} / {this.state.images.length}</span>
									</div>
									<div className="h-color-fg-hint modal__footer-text">
										<span className="h-color-fg">{Translations.get('From')}: &nbsp;</span> {currentImage.actorName ?
										<div className='modal__footer-link' target={currentImage.actorUrl} onClick={this.onLinkClick}><span className="h-text-highlight">{currentImage.actorName}</span></div> : ''}
										&nbsp;{this.props.actor && this.state.isLoggedIn ?
										<ActorPin actorId={this.props.actor.id}
										          initialIsFavoriteOfGuest={this.state.isModelGuestFavorite || currentImage.isModelGuestFavorite}
										/> : ''}
									</div>
								</div>}
							{this.props.hideAlbumInfo &&
								<span
									className="h-text-grey">{Translations.get('Picture')} {this.state.selectedIndex + 1} / {this.state.images.length}</span>}
						</div>
						{!this.props.hideVoting && <div className="h-right">
							<div className="gallery__controls-table">
								{this.state.isLoggedIn &&
									<div className="gallery__controls-cell -button -bg-light">
										<MediaPin pictureId={currentImage.id} initialIsFavorite={currentImage.pinned} tooltipPlacement="top"/>
									</div>}
								{this.state.isLoggedIn &&
									<div className={"gallery__controls-cell -button -bg-light" + (currentImage.isLiked ? ' is-active' : '')}>
										<Like pictureId={currentImage.id} initialIsLiked={currentImage.isLiked} tooltipPlacement="top"/>
									</div>}
								{this.state.isLoggedIn &&
									<div className={"gallery__controls-cell -button -bg-light" + (currentImage.isDisliked ? ' is-active' : '')}>
										<Dislike pictureId={currentImage.id} initialIsDisliked={currentImage.isDisliked} tooltipPlacement="top"/>
									</div>}
							</div>
						</div>}
						{!this.props.hideContestVoting &&
							<div className="gallery__controls--votingcontainer">
								<ContestVoting
									contestId={currentImage.contestId}
									actorId={currentImage.actorId}
									mediaId={currentImage.mediaId}
									mediaType={currentImage.mediaType}
									guestId={currentImage.guestId}
									isVotingAllowed={currentImage.isVotingAllowed}
									isVoted={currentImage.isVoted}
									albumId={currentImage.albumId}
									styleClass={"-margin-r-xs"}
								/> {currentImage.contestVotes > 0 &&
								<span className="gallery__controls--votingcount">{currentImage.contestVotes}</span>}
							</div>
						}
						{descText}
					</div>}
				</div>
				{!this.state.showModal && descText}
			</div>
		);

		return (
			<div className="gallery__viewer h-height-full">
				{this.state.showModal &&
					<Modal hideCloseButton={this.state.isControlsHidden}
					       instanceId={this.props.instanceId}
					       onClose={this.onModalClose}
					>{getContent}</Modal>}
				{!this.state.showModal && getContent}
			</div>
		);
	}
}

/**
 *
 * @param {string}   containerId
 * @param {Object[]} photos
 * @param {boolean}  initShowModal
 * @param {int}      selectedIndex
 * @param {boolean}  hideAlbumInfo
 * @param {boolean}  hideVoting
 * @param {boolean}  hideContestVoting
 * @param {Object[]} callbacks
 */
function openPicturesInViewer(containerId, photos, initShowModal, selectedIndex, hideAlbumInfo, hideVoting, hideContestVoting, callbacks) {
	let galleryContainer = document.getElementById(containerId);
	if (!galleryContainer) {
		galleryContainer = document.createElement("div");
		galleryContainer.setAttribute("id", "layer-album-modal");
		document.body.appendChild(galleryContainer);
	}
	const {onOpenMediaViewer, onCloseModalBox, navigateTo} = callbacks;
	ReactDOM.render(
		<Viewer initialImages={photos}
		        initShowModal={initShowModal}
		        selectedIndex={selectedIndex}
		        hideVoting={hideVoting}
		        hideAlbumInfo={hideAlbumInfo}
		        hideContestVoting={hideContestVoting}
		        onOpenMediaViewer={onOpenMediaViewer}
		        onCloseModalBox={onCloseModalBox}
				navigateTo={navigateTo}

		/>,
		galleryContainer
	);

	PjaxWrapper.addUnloadFunc(() => {
		ReactDOM.unmountComponentAtNode(galleryContainer);
	});
}

Viewer.propTypes = {
	actor:                    PropTypes.object,
	actorBaseLink:            PropTypes.string,
	initialImages:            PropTypes.array,
	initialIsFavoriteOfGuest: PropTypes.bool,
	initShowModal:            PropTypes.bool,
	instanceId:               PropTypes.string,
	isMobile:                 PropTypes.bool,
	loader:                   PropTypes.element,
	navigateTo:               PropTypes.func,
	onChange:                 PropTypes.func,
	onShowModal:              PropTypes.func,
	selectedIndex:            PropTypes.number,
	showControlsNonModal:     PropTypes.bool,
	hideAlbumInfo:            PropTypes.bool,
	hideVoting:               PropTypes.bool,
	hideContestVoting:        PropTypes.bool,
	votingElement:            PropTypes.object,
	onOpenMediaViewer:        PropTypes.func,
	onCloseModalBox:          PropTypes.func,
	srcSet:                   PropTypes.string,
};

Viewer.defaultProps = {
	actorBaseLink:        '',
	initialImages:        [],
	isMobile:             false,
	loader:               null,
	selectedIndex:        0,
	showControlsNonModal: true,
	hideAlbumInfo:        false,
	hideVoting:           false,
	hideContestVoting:    true,
	srcSet:               '',
	onShowModal:          () => {
	},
	onOpenMediaViewer:    () => {
	},
	onCloseModalBox:      () => {
	},
};

export {
	Viewer,
	openPicturesInViewer,
};