import React, {useEffect, useState}                                        from "react";
import PropTypes                                                           from 'prop-types';
import Flux                                                                from "../../flux/Flux";
import {addPreventScrolling, removePreventScrolling, shouldShowExitIntent} from '../../utils/CommonUtils';
import Modal                                                               from "../Modal";
import Translations                                                        from '../../utils/Translations';
import withVideos                                                          from "../HigherOrderComponents/Videos/withVideos.js";
import AlbumBuyButtons                                                     from "../ActorProfile/ActorProfileVideos/AlbumBuyButtons.js";
import withVideosGrid2Adapter                                              from "../HigherOrderComponents/Videos/withVideosGrid2Adapter.js";
import VideoTile                                                           from "../Grid2/Tiles/VideoTile.js";
import withStaticLoader                                                    from "../HigherOrderComponents/Utility/withStaticLoader.js";
import Grid2                                                               from "../Grid2/Grid2.js";

const t = (key) => Translations.get(`exit-intent.${key}`);

const COLORS = {
	BLOND:   {
		KEY:   'blond',
		VALUE: t('blond'),
	},
	brunett: {
		KEY:   'brunett',
		VALUE: t('brunett'),
	},
	RED:     {
		KEY:   'red',
		VALUE: t('red'),
	},
};

const GetVideo = ({loading, videos, onBuy, isMobile, ...props}) => {
	if (loading || !videos) {
		return null;
	}

	const currentVideo = {
		...videos[0],
		actorId:           videos[0].model.id,
		crossPrice:        videos[0].basePrice.value,
		videoPrice:        videos[0].price.value,
		hasActiveDiscount: videos[0].basePrice.value > videos[0].price.value,
		needBuying:        !videos[0].guestInfo.bought,
		albumId:           videos[0].id,
	};

	// disable click callback
	props.tileData[0].callback = () => false;

	const onBuyWithUrl = () => {
		onBuy(currentVideo.linkVX);
	};

	return <>
		<Grid2
			{...props}
			defaultTileClass={VideoTile}
			columns={{xs: 1, sm: 1, md: 1, lg: 1}}
			defaultTileConfig={{footer: {show: {likes: false}}, badges: {textBadges: {show: {price: Flux.Guest.isLoggedIn()}}}}}
			tileSizes={[{index: 0, size: isMobile ? 1 : 2}]}
		/>
		<div className="h-mt-30">
			<AlbumBuyButtons actorDataId={currentVideo.actorId}
			                 isAlbumBought={currentVideo.isBought}
			                 baseClassName="vx_shaka--ui"
			                 needVip={currentVideo.needVip}
			                 hasActiveDiscount={currentVideo.hasActiveDiscount}
			                 isAccessAllowed={currentVideo.isAccessAllowed}
			                 needPayment={currentVideo.needPayment}
			                 needBuying={currentVideo.needBuying}
			                 needAvs={currentVideo.needAvs}
			                 price={currentVideo.videoPrice}
			                 crossPrice={currentVideo.crossPrice}
			                 id={currentVideo.albumId}
			                 contestLink={currentVideo.contestLink}
			                 isVip30={currentVideo.isVip30}
			                 canBuy={currentVideo.canBuy}
			                 hasComment={currentVideo.hasComment}
			                 onBuyModalId={currentVideo.onBuyModalId}
			                 onBuySuccess={onBuyWithUrl}
			                 sticky={false}
			></AlbumBuyButtons>
		</div>
	</>;

//	return <div className="exit-intent__video"><VideoPlayer
//		initialVideos={videoArray}
//		autoplay={false}
//		isMobile={isMobile}
//		showBigPlayButton={false}
//		fullscreenOnLandscape={false}
//		onBuy={onBuy}
//		videoBadgesConfig={{
//			getPrice:   currentVideo.videoPrice,
//			needBuying: currentVideo.needBuying,
//		}}
//	/>
//	</div>;
};

GetVideo.propTypes = {
	isMobile: PropTypes.bool,
	loading:  PropTypes.bool,
	videos:   PropTypes.array,
	onBuy:    PropTypes.func,
	tileData: Grid2.propTypes.tileData,
};

export default function ModalboxExitIntent({onClose, isMobile, incentive, id}) {
	const [color, setColor]         = useState('');
	const [showModal, setShowModal] = useState(false);

	const body = document.querySelector('html');

	const onLeave = () => {
		if (!shouldShowExitIntent()) {
			return null;
		} else {
			Flux.Conversion.blockIncentive(incentive, {color, accept: false});
		}

		setShowModal(true);
	};

	const chooseColor = (color) => {
		setColor(color);
		Flux.Conversion.blockIncentive(incentive, {color, accept: false});
	};

	// on mount
	useEffect(() => {
		const timeout = window.setTimeout(() => {
			// register on mount
			history.pushState(null, null, window.location.href);
			window.addEventListener('popstate', onLeave);
			body && body.addEventListener('mouseleave', onLeave);
		}, incentive.activationDelay * 1000);

		// unregister on unmount
		return () => {
			body && body.removeEventListener('mouseleave', onLeave);
			window.removeEventListener('popstate', onLeave);
			clearTimeout(timeout);
		};
	}, []);

	if (!showModal) {
		return <div id={id} />;
	}

	addPreventScrolling();
	const close = () => {
		Flux.Conversion.closeIncentive(incentive, {accept: false, color});
		removePreventScrolling();
		onClose();
	};

	const onBuy = (targetUrl) => {
		Flux.Conversion.closeIncentive(incentive, {accept: true, color});
		removePreventScrolling();
		onClose();
		if (targetUrl) {
			window.location.href = targetUrl;
		}
	};

	const getGrid = () => (
		<div className="exit-intent__grid-wrapper">
			<div className="exit-intent__grid">
				<div className="exit-intent__grid-item" onClick={() => chooseColor(COLORS.BLOND.KEY)}>
					<img className="exit-intent__grid-item__image" src="/assets/img/exit-intent/blond.webp"></img>
					<div className="exit-intent__grid-item__overlay-color exit-intent__grid-item__overlay-color--blond"></div>
					<div className="exit-intent__grid-item__overlay-text">{COLORS.BLOND.VALUE}</div>
				</div>
				<div className="exit-intent__grid-item" onClick={() => chooseColor(COLORS.brunett.KEY)}>
					<img className="exit-intent__grid-item__image" src="/assets/img/exit-intent/brown.webp"></img>
					<div className="exit-intent__grid-item__overlay-color exit-intent__grid-item__overlay-color--brunett"></div>
					<div className="exit-intent__grid-item__overlay-text">{COLORS.brunett.VALUE}</div>
				</div>
				<div className="exit-intent__grid-item" onClick={() => chooseColor(COLORS.RED.KEY)}>
					<img className="exit-intent__grid-item__image" src="/assets/img/exit-intent/red.webp"></img>
					<div className="exit-intent__grid-item__overlay-color exit-intent__grid-item__overlay-color--red"></div>
					<div className="exit-intent__grid-item__overlay-text">{COLORS.RED.VALUE}</div>
				</div>
			</div>
		</div>
	);

	const GetVideoComponent = withVideos(withStaticLoader(withVideosGrid2Adapter(GetVideo)));

	return <Modal className="-theme-dark" modalClassName="-center-content" hideCloseButton divProps={{id}}>
		<div className="exit-intent__wrapper">
			<div className="exit-intent__headline" dangerouslySetInnerHTML={{__html: t('headline')}}></div>
			<div className="exit-intent__text" dangerouslySetInnerHTML={{__html: t(isMobile ? 'text-mobile' : 'text')}}></div>
			{color ? <div className="exit-intent__video">
				<div className="exit-intent__video__wrapper">
					<GetVideoComponent filter={{ids: [incentive.albumIds[color]], types: [{bought: true}]}}
					                   isMobile={isMobile}
					                   onBuy={onBuy} />
				</div>
			</div> : getGrid()}
			<div className="exit-intent__text h-cursor-pointer" onClick={close}>{t('close-text')}</div>
		</div>
	</Modal>;
}

ModalboxExitIntent.propTypes = {
	id:        PropTypes.string,
	onClose:   PropTypes.func,
	isMobile:  PropTypes.bool,
	incentive: PropTypes.object,
};
