import React        from 'react';
import PropTypes    from 'prop-types';
import Translations from '../../../../utils/Translations';

const AlbumButtonVip = (props) =>
	<div className={`${props.baseClassName}__button--vip`} onClick={props.onClick}>
		<div className={`${props.baseClassName}__icon--button icon -icon-keylock_open`} />
		<div className={`${props.baseClassName}__text--button`}>{Translations.get('ActorProfileBtnBecomeVip')}</div>
	</div>
;

AlbumButtonVip.propTypes = {
	baseClassName: PropTypes.string,
	onClick:       PropTypes.func,
};

export default AlbumButtonVip;
