import React               from 'react';
import Translations        from '../../../utils/Translations';
import PropTypes           from 'prop-types';
import withDailybonusBadge from '../../HigherOrderComponents/Dailybonus/withDailybonusBadge';

const AlbumButtonBadgePrevius = (props) =>
	props.is365OfferPrevious ? <div className='badge-365'><i className='icon -icon-videosale-line' /> {Translations.get('VipOffer')}</div>
		: null;

AlbumButtonBadgePrevius.propTypes = {
	is365OfferPrevious: PropTypes.bool,
};

export default withDailybonusBadge(AlbumButtonBadgePrevius);
