import React                                           from 'react';
import PropTypes                                       from 'prop-types';
import {TileDisplayConfigType}                         from "../Grid2";
import VXButton                                        from "../../SimpleElements/VXButton/VXButton";
import {COLOR_TYPE_CALL_TO_ACTION, COLOR_TYPE_DEFAULT} from "../../SimpleElements/VXButton/ButtonHelper";
import {VXButtonLineConfig, VXButtonLineStyle}         from "../../SimpleElements/VXButton/VXButtonConfig";
import STYLES                                          from "../../SimpleElements/VXButton/EnumVXButton";
import Translations                                    from "../../../utils/Translations";
import Flux                                            from "../../../flux/Flux";
import {VXPay, VXPayAction}                            from "../../../utils/VXPay";
import Grid2Helper                                     from "../../../utils/Grid2Helper";
import {mergeDeep}                                     from "../../../utils/CommonUtils";
import ReloadHelper                                    from "../../../utils/ReloadHelper";
import UrlBuilder                                      from "../../../utils/UrlBuilder";

const BUTTON_STYLE = new VXButtonLineStyle([STYLES.UPPERCASE, STYLES.LIGHT]);

export const TYPES = {
	DEFAULT:            'DEFAULT',
	ALL_VIDEOS:         'ALL_VIDEOS',
	ACTOR_VIDEO_SEARCH: 'ACTOR_VIDEO_SEARCH',
	ACTOR_PHOTO_SEARCH: 'ACTOR_PHOTO_SEARCH',
};

const ActorTooFewItemsTile = props => {
	const data   = {...ActorTooFewItemsTile.defaultProps.data, ...props.data};
	const config = mergeDeep({}, ActorTooFewItemsTile.defaultProps.config, props.config);

	const contents          = getTileContents(data, config);
	const additionalClasses = Grid2Helper.getSizeClasses(props.displayConfig.tileSize);

	return (
		<div className={'grid-2__tile__type-actor-too-few-items ' + additionalClasses}>
			<div className={'grid-2__tile__type-actor-too-few-items__inner'}>
				<h2 className={'grid-2__tile__type-actor-too-few-items__header' + (contents.titleClass ? ` ${contents.titleClass}` : '')}>{contents.title}</h2>
				<div className={'grid-2__tile__type-actor-too-few-items__button-wrapper'}>
					<VXButton color={contents.btnColor} line1={contents.btnLine1} icon={contents.btnIcon} onClick={contents.btnOnClick} />
				</div>
			</div>
		</div>
	);
};

function getTileContents(data, config) {
	const isGuestLoggedIn = Flux.Guest.isLoggedIn();

	const contents = {
		title:      '',
		titleClass: '',
		btnColor:   '',
		btnLine1:   '',
		btnIcon:    '',
		btnOnClick: undefined,
	};

	switch (config.type) {
		case TYPES.ALL_VIDEOS:
			contents.title      = Translations.get('ShowAllVideosActorVideos').replace('%s', data.actorName);
			contents.titleClass = '-all-videos';
			contents.btnColor   = COLOR_TYPE_CALL_TO_ACTION;
			contents.btnLine1   = [new VXButtonLineConfig(Translations.get('ShowAll'), BUTTON_STYLE)];
			contents.btnOnClick = getAllVideosOnClickHandler(data.actorName);
			break;
		case TYPES.ACTOR_VIDEO_SEARCH:
			contents.title      = data.hasSearchResults ? Translations.get('SearchVideosInAllModels') : Translations.get('MissingActorSearchTagsVideosInfo', data.actorName);
			contents.btnColor   = COLOR_TYPE_DEFAULT;
			contents.btnLine1   = [new VXButtonLineConfig(Translations.get('SearchInAllModels'), BUTTON_STYLE)];
			contents.btnIcon    = '-icon-search-full';
			contents.btnOnClick = getActorVideoSearchTypeOnClickHandler(data.searchTags);
			break;
		case TYPES.ACTOR_PHOTO_SEARCH:
			contents.title      = Translations.get('MissingActorPicturesInfo').replace(':actorName', data.actorName);
			contents.btnColor   = isGuestLoggedIn ? COLOR_TYPE_DEFAULT : COLOR_TYPE_CALL_TO_ACTION;
			contents.btnLine1   = [new VXButtonLineConfig(Translations.get(isGuestLoggedIn ? 'WriteMessage' : 'SignUpNow'), BUTTON_STYLE)];
			contents.btnIcon    = isGuestLoggedIn ? '-icon-chat-flat-line' : '-icon-arrow-right-full';
			contents.btnOnClick = data.callback;
			break;
		case TYPES.DEFAULT:
		default:
			contents.title      = Translations.get('MissingActorVideosInfo').replace(':actorName', data.actorName);
			contents.btnColor   = isGuestLoggedIn ? COLOR_TYPE_DEFAULT : COLOR_TYPE_CALL_TO_ACTION;
			contents.btnLine1   = [new VXButtonLineConfig(Translations.get(isGuestLoggedIn ? 'WriteMessage' : 'SignUpNow'), BUTTON_STYLE)];
			contents.btnIcon    = isGuestLoggedIn ? '-icon-chat-flat-line' : '-icon-arrow-right-full';
			contents.btnOnClick = getDefaultTypeOnClickHandler(isGuestLoggedIn, data.actorId);
			break;
	}

	return contents;
}

/**
 * @return {function}
 */
function getDefaultTypeOnClickHandler(isGuestLoggedIn, actorId) {
	if (isGuestLoggedIn) {
		return () => Flux.Messenger.selectChannelByActorId(actorId, true);
	} else {
		const action = new VXPayAction(Flux.Constants.ActionTypes.Messenger.SELECT_CHANNEL, {
			targetId:             actorId,
			storeAutoOpenSetting: true,
		});

		if (Flux.Guest.isAnonymousVoicecall()) {
			return () => VXPay.openSignup({
				host:  actorId,
				texts: {
					BonusTextCustom: Translations.get('VoicecallVXPayTeasserText'),
				},
			}, action);
		} else {
			return () => VXPay.openSignupOrLogin({
				host: actorId,
			}, action);
		}
	}
}

/**
 * @return {function}
 */
function getActorVideoSearchTypeOnClickHandler(tags) {
	const url = UrlBuilder.buildVideoSearchUrl(tags[0] || '');
	return event => ReloadHelper.reload(url, event);
}

/**
 * @return {function}
 */
function getAllVideosOnClickHandler(actorName) {
	const url = UrlBuilder.buildActorProfileVideosUrl(actorName);
	return event => ReloadHelper.reload(url, event);
}

ActorTooFewItemsTile.propTypes = {
	data:          PropTypes.shape({
		id:          	  PropTypes.number,
		name:        	  PropTypes.string,
		searchTags:       PropTypes.arrayOf(PropTypes.string),
		hasSearchResults: PropTypes.bool,
	}),
	displayConfig: TileDisplayConfigType,
	config:        PropTypes.shape({
		type: PropTypes.oneOf(Object.keys(TYPES).map(key => TYPES[key])),
	}),
};

ActorTooFewItemsTile.defaultProps = {
	data:   {
		searchTags:       [],
		hasSearchResults: false,
	},
	config: {
		type: TYPES.DEFAULT,
	},
};

export default ActorTooFewItemsTile;