import React                from 'react';
import PropTypes            from 'prop-types';
import Footer               from './Footer';
import VideoPreview         from './VideoPreview';
import CloseButton          from '../Conversion/CloseButton';
import EnumAlbumButtonsType from '../ActorProfile/ActorProfileVideos/AlbumTypeButtons/EnumAlbumButtonsType';
import Translations         from '../../utils/Translations';

const VideoPreviewEndedAvsBox = (props) =>
	<div className={'conversion-box__wrapper ' + (props.isMobile ? '-mobile' : '-pos-abs-center') + props.className}>
		<div className="conversion-box__content-container">
			<CloseButton {...props} />
			<div className="conversion-box__content-row row">
				<div className="col-xs-5 conversion-box__content-left -video-image-teaser">
					<VideoPreview previewImageUrl={props.videoPlayerIncentive.albumPreviewPicture.pictureUrl}>
						<div className="feature-teaser-box__wrapper" />
					</VideoPreview>
				</div>
				<div className="col-xs-7 conversion-box__content-right">
					<div className="feature-teaser-box row">
						<div className="col-xs-12 feature-teaser-box__subheadline h-over-ellipsis"
						     title={props.videoPlayerIncentive.albumTitle}
						     dangerouslySetInnerHTML={{__html: props.videoPlayerIncentive.albumTitle}}
						/>
						<div className="col-xs-12 feature-teaser-box__line" />
						<div className="col-xs-12 feature-teaser-box__text -text-avs h-text-uppercase"
						     dangerouslySetInnerHTML={{__html: Translations.get('VideoAVSBoxHeadLine')}}
						/>
						<div className="col-xs-12 feature-teaser-box__headline h-text-uppercase"
						     dangerouslySetInnerHTML={{__html: Translations.get('VideoPurchaseBoxHeadLine')}}
						/>
					</div>
				</div>
			</div>
		</div>
		<Footer type={EnumAlbumButtonsType.avs} currentVideo={props.currentVideo} videoPlayerIncentive={props.videoPlayerIncentive} />
	</div>;


VideoPreviewEndedAvsBox.propTypes    = {
	className:            PropTypes.string,
	type:                 PropTypes.string,
	isMobile:             PropTypes.bool,
	onCloseFn:            PropTypes.func,
	showCloseButton:      PropTypes.bool,
	currentVideo:         PropTypes.object,
	videoPlayerIncentive: PropTypes.shape({
		albumId:             PropTypes.number.isRequired,
		albumTitle:          PropTypes.string.isRequired,
		albumPreviewPicture: PropTypes.object.isRequired,
	}).isRequired,
};

VideoPreviewEndedAvsBox.defaultProps = {
	className:       '',
	isMobile:        false,
	onCloseFn:       null,
	showCloseButton: false,
};

export default VideoPreviewEndedAvsBox;
